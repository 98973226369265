import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import InputCheckbox, { InputCheckBoxProps } from '~/components/input/InputCheckbox';

export default function ZodFieldCheckbox({
  name,
  value,
  force = true,
  ...props
}: Omit<InputCheckBoxProps, 'checked' | 'onChange'> & {
  name: string;
  value?: string;
  force?: boolean;
}) {
  const [prefixed, zodValue, setValue] = useZodFormFieldSingle(name);

  const checkedValue = value ?? 'true';
  const uncheckedValue = value ? '' : 'false';
  const checked = zodValue === checkedValue;

  return (
    <InputCheckbox
      {...props}
      name={checked || force ? prefixed : undefined}
      checked={checked}
      value={checked ? checkedValue : uncheckedValue}
      onChange={(on) => {
        setValue(on ? checkedValue : uncheckedValue);
      }}
    />
  );
}
